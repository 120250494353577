



























import Vue from "vue";
import {
  base64encode,
  callClientByTagA,
  detectOS,
  isMacThunder,
} from "@/utils/util";
import xlClientMixin from "@/mixins/pc-client";
import macClientMixin from "@/mixins/mac-client";

export default Vue.extend({
  name: "DownloadDialog",
  mixins: [xlClientMixin, macClientMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    selectedFiles() {
      if (this.files.length) {
        return this.files;
      }
      return this.info.files;
    },
    platform() {
      return process.client ? detectOS() : "Windows";
    },
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.stat("xiaozhan_guid_install_client_pop_show", {});
      }
    },
  },
  mounted() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    close() {
      this.$emit("close");
    },
    iconClose() {
      this.stat("xiaozhan_guid_install_client_pop_click", {
        clickid: "close",
      });
      this.close();
    },
    btnClose() {
      this.close();
    },
    handleClick() {
      this.close();
    },
    async downloadClient() {
      this.stat("xiaozhan_guid_install_client_pop_click", {
        clickid: "install",
      });

      await this.clearClipBoard();
      this.writeClipBoard();

      if (this.platform === "macOS") {
        window.open(this.macDownloadUrl);
      } else {
        window.open(this.clientDownloadUrl);
      }
    },
    // 避免取回同一个资源，剪切板内容不变，客户端不识别
    async clearClipBoard() {
      return this.$copyText("clear clipboard");
    },
    async callNative() {
      this.stat("xiaozhan_guid_install_client_pop_click", {
        clickid: "download",
      });

      await this.clearClipBoard();
      await this.writeClipBoard();

      if (this.platform === "macOS" && this.info.directLinks) {
        this.handleMacDownload(
          "thunder://QUFEdW1teUxpbmsvQ29weS1Gcm9tLUNsaXBib2FyZFpa"
        );
        return;
      }

      callClientByTagA(this.callClientProtocol);
    },
    async writeClipBoard() {
      if (this.platform === "macOS" && this.info.directLinks) {
        await this.$copyText(this.info.directLinks);
        return;
      }

      const files = this.selectedFiles.map((item: any) => {
        const { id, kind, name, size, icon_link } = item;
        return { id, kind, name, size, icon_link };
      });

      let shareId = this.info.source_id || this.info.share_id;

      if (shareId) {
        shareId = shareId.split("").reverse().join("");
      }

      let params = {
        files,
        userId: this.curUser.userId,
        fromShare: true, // 小站只有分享的，如果是文件列表，需要传递false
        shareId,
        passCodeToken: "",
        from: "xiaozhan",
        needLogin: false,
        selectNav: window.native ? "find-page" : "",
      };

      const writeMsg = base64encode(encodeURIComponent(JSON.stringify(params)));
      await this.$copyText(
        this.clientClipBoardProtocol.replace("content", writeMsg)
      );
    },
  },
});





























import Vue from "vue";
import store from "store";

export default Vue.extend({
  name: "save-dialog",
  props: ["visible"],
  data() {
    return {
      checked: [],
    };
  },
  computed: {
    isPcClient() {
      if (process.client) {
        return !!window.native;
      }
      return this.$store.state.isPCNative;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.stat("xiaozhan_collect_new_user_guid_pop_show", {});
      }
    },
  },
  mounted() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    close() {
      this.$emit("close");
      const saveSuccessDialogVisible = store.get("save_success_dialog_visible");
      if (this.checked[0] && !saveSuccessDialogVisible) {
        store.set("save_success_dialog_visible", "hidden");
      }
    },
    iconClose() {
      this.stat("xiaozhan_collect_new_user_guid_pop_click", {
        clickid: "close",
        is_choose_no_remind: this.checked[0] ? 1 : 0,
      });
      this.close();
    },
    btnClose() {
      this.stat("xiaozhan_collect_new_user_guid_pop_click", {
        clickid: "known",
        is_choose_no_remind: this.checked[0] ? 1 : 0,
      });
      this.close();
    },
    handleClick() {
      this.stat("xiaozhan_collect_new_user_guid_pop_click", {
        clickid: "look",
        is_choose_no_remind: this.checked[0] ? 1 : 0,
      });
      this.close();
    },
  },
});

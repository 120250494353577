









import Vue from "vue";

import ssoMixin from "@/mixins/sso";
import feedMixin from "@/mixins/feed";

export default Vue.extend({
  name: "waterfall-header",
  mixins: [ssoMixin, feedMixin],
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
  },
  mounted() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
  },
});






































import Vue from "vue";
import store from "store";
import SiteHeader from "@/components/Header.vue";
import SiteFooter from "@/components/Footer.vue";
import Loader from "@/components/Loader.vue";
import SaveDialog from "@/components/SaveDialog.vue";
import DownloadDialog from "@/components/DownloadDialog.vue";
import ContentEmpty from "@/components/Empty.vue";
import feedMixin from "@/mixins/feed";
import eventEmitter from "@/utils/eventEmitter";
import Waterfall from "@/components/Waterfall.vue";
import WaterfallHeader from "@/components/WaterfallHeader.vue";
import eventMap from "@/utils/event-name";
import { joinUrlQueryString } from "@/utils/util";
import { IFeedItem } from "@/types/post";

export default Vue.extend({
  mixins: [feedMixin],
  components: {
    SiteHeader,
    SiteFooter,
    Loader,
    SaveDialog,
    DownloadDialog,
    ContentEmpty,
    Waterfall,
    WaterfallHeader,
  },
  async asyncData({ req, res, params, store, query, error }) {
    try {
      store.commit("post/SET_CONTENT_SEARCH_LIST", {
        refresh: true,
        list: [],
        pageToken: "",
        hasMore: true,
        total: 0,
      });

      const [feedRes] = await Promise.all([
        store.dispatch("post/contentSearch", {
          refresh: true,
          s: params.keyword,
        }),
      ]);

      return {
        feeds: feedRes.data,
        loading: false,
      };
    } catch (e) {
      console.log("asyncData search error >>>", e);
      error({ statusCode: 500, message: "暂时无法获取数据" });
    }
  },
  data() {
    return {
      title: `${
        this.$route.params.keyword || "搜索结果"
      } - 迅雷小站 - 精品资源下载站`,
      visible: false,
      loading: true,
      downloadVisible: false,
      feedToDownload: {},
      keyword: this.$route.params.keyword || "",
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    searchFeed() {
      return this.$store.state.post.contentSearchFeed;
    },
    hasMore() {
      return this.searchFeed.hasMore;
    },
    total() {
      return this.searchFeed.total;
    },
    list() {
      return this.searchFeed.list;
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
  },
  head() {
    return {
      title: this.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: "搜索迅雷小站精品资源",
        },
      ],
    };
  },
  mounted() {
    eventEmitter.on("save-success-search", (type: string) => {
      if (type === "dialog") {
        this.visible = true;
      } else {
        this.$message({
          message: "收藏成功",
          type: "success",
        });
      }
    });

    eventEmitter.on(
      eventMap.CONTENT_SEARCH,
      (data: { keyword: string; from: string }) => {
        const searchRoutePath = `/search/${data.keyword}?k_from=search&${this.queryStr}`;
        this.$router.push(searchRoutePath);
      }
    );

    eventEmitter.on("feed-download-fail", (item: IFeedItem) => {
      this.feedToDownload = item;
      this.downloadVisible = true;
    });

    eventEmitter.on("open-mac-download", ({ files, links }: any) => {
      this.feedToDownload = { files, directLinks: links };
      this.downloadVisible = true;
    });

    setTimeout(() => {
      const type = store.get("keyword_search_type");
      this.stat("xiaozhan_search_result", {
        from: this.$route.query.k_from || "search",
        number: this.total,
        keywords: encodeURIComponent(this.keyword),
        type,
      });
      store.remove("keyword_search_type");
    }, 1000);

    // TODO: 客户端请求
    // this.$store.dispatch("post/contentSearch", {
    //   refresh: true,
    //   s: this.keyword,
    // });

    // this.genFeedStatData(this.searchFeed.list, "search", this.category, "");
  },
  beforeDestroy() {
    eventEmitter.off("save-success-search", 0);
    eventEmitter.off("feed-download-fail", 0);
    eventEmitter.off("open-mac-download", 0);
    eventEmitter.off(eventMap.CONTENT_SEARCH, 0);
  },
  methods: {
    async getFeedList(refresh = true) {
      const params: any = {
        refresh,
        s: this.keyword,
      };

      try {
        this.loading = true;
        await this.$store.dispatch("post/contentSearch", params);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$message({
          type: "warning",
          message: "搜索失败，请稍后重试",
        });
      }
    },
    loadMore(visible: boolean) {
      if (visible) {
        setTimeout(() => {
          this.getFeedList(false);
        }, 0);
      }
    },
  },
});

import { render, staticRenderFns } from "./_keyword.vue?vue&type=template&id=44fa59a0&"
import script from "./_keyword.vue?vue&type=script&lang=ts&"
export * from "./_keyword.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/css/dialog.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./_keyword.vue?vue&type=style&index=1&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WaterfallHeader: require('/var/static/components/WaterfallHeader.vue').default,Waterfall: require('/var/static/components/Waterfall.vue').default,Loader: require('/var/static/components/Loader.vue').default,SaveDialog: require('/var/static/components/SaveDialog.vue').default,DownloadDialog: require('/var/static/components/DownloadDialog.vue').default})

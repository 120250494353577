











import Vue from "vue";

export default Vue.extend({
  name: "site-empty",
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    withPic: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {};
  },
  methods: {},
});

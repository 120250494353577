




























































import Vue from "vue";

export default Vue.extend({
  name: "site-footer",
  data() {
    return {
      currentYear: new Date().getFullYear() || "2022",
      activeKey: 0,
      tabTitle: ["友情链接", "热门资源", "热门站点"],
      linkList: [
        {
          title: "迅雷官网",
          link: "https://xunlei.com/",
        },
        {
          title: "用户中心",
          link: "https://i.xunlei.com/",
        },
        {
          title: "迅雷电影",
          link: "https://zhan.xunlei.com/search/%E7%94%B5%E5%BD%B1?entry_source=xlmovie",
        },
        {
          title: "迅雷直播",
          link: "https://live.xunlei.com/",
        },
        {
          title: "迅雷产品",
          link: "https://dl.xunlei.com/",
        },
        {
          title: "迅雷论坛",
          link: "https://bbs.xunlei.com/",
        },
        {
          title: "用户协议",
          link: "https://zhan.xunlei.com/term/privacy",
        },
        {
          title: "客服中心",
          link: "https://help.xunlei.com/",
        },
      ],
      linkList1: [
        {
          title: "办公文档",
          link: "https://zhan.xunlei.com/zone/VMmv_gypnn-PBqa_tqKd7T8B00",
        },
        {
          title: "设计素材",
          link: "https://zhan.xunlei.com/zone/VMmvaYBVOJxrafCkzO3uvUY000",
        },
        {
          title: "壁纸屏保",
          link: "https://zhan.xunlei.com/zone/VMmvb7manEkVN2SIYQzCeGdQ00",
        },
        {
          title: "实用字体",
          link: "https://zhan.xunlei.com/zone/VMmv_uGc717uqQDBdj6FdGCx00",
        },
      ],
      linkList2: [
        {
          title: "ppt精品大全",
          link: "https://zhan.xunlei.com/space/749193845",
        },
        {
          title: "吾优实用软件",
          link: "https://zhan.xunlei.com/space/694923179",
        },
        {
          title: "超哥软件",
          link: "https://zhan.xunlei.com/space/680420593",
        },
        {
          title: "搜罗派",
          link: "https://zhan.xunlei.com/space/614402745",
        },
        {
          title: "遇见漫画壁纸",
          link: "https://zhan.xunlei.com/space/749193844",
        },
        {
          title: "动态壁纸控",
          link: "https://zhan.xunlei.com/space/749193838",
        },
        {
          title: "4K桌面壁纸",
          link: "https://zhan.xunlei.com/space/749193835",
        },
        {
          title: "装机必备",
          link: "https://zhan.xunlei.com/space/749193817",
        },
        {
          title: "找书老司机",
          link: "https://zhan.xunlei.com/space/749193827",
        },
        {
          title: "学霸养成记",
          link: "https://zhan.xunlei.com/space/749193811",
        },
        {
          title: "你学废了吗",
          link: "https://zhan.xunlei.com/space/749193814",
        },
        {
          title: "好玩行业调查",
          link: "https://zhan.xunlei.com/space/749193831",
        },
        {
          title: "吾爱分享网",
          link: "https://zhan.xunlei.com/space/622975699",
        },
        {
          title: "马小帮的精选宝库",
          link: "https://zhan.xunlei.com/space/403071989",
        },
        {
          title: "工具软件库",
          link: "https://zhan.xunlei.com/space/824332921",
        },
      ],
    };
  },
  methods: {
    handleActive(index: number) {
      this.activeKey = index;
    },
  },
});









import Vue from "vue";

export default Vue.extend({
  name: "loader",
  data: () => ({
    loading: false,
  }),
  methods: {},
});
